<template>
  <select-map page="publish" title="Publish" />
</template>

<script>
import selectMap from "../components/SelectMap.vue";

export default {
  name: "Publish",
  components: {
    selectMap
  }
};
</script>
